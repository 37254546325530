.infographic {
    max-width: 720px;
    width: 100%;
}
.emptyImage {
    width: 80%;
}

.subpageBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

h4.desktopMainPoint {
    color: #3f6b72;
    font-weight: bold;
}

.focusText {
    max-width: 400px;
    padding-left: 1rem;
    padding-right: 1rem;
}
.areaContainer {
    width: 100%;
}

.agilityText {
    max-width: 400px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.productivityText {
    max-width: 400px;
    padding-left: 1rem;
    padding-right: 1rem;
}
.sectionContainer {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.desktopVideo {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.arrowLink {
    margin-top: 2rem;
    display: flex;
}

.tryButtonContainer {
    margin-bottom: 6rem;
}

.mainContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

h5.subHeader {
    font-weight: bold;
}

.agilityInfographicVideoContainer {
    width: 80%;
}

.agilityInfographicContainer {
    margin: 1rem 1rem 1rem 1rem;
}


.productivityInfographicContainer {
    margin: 1rem 1rem 1rem 1rem;
}


.focusInfographicContainer {
    margin: 1rem 1rem 1rem 1rem;
}

.zoomIcon {
    margin-top: -1.5rem;
}