html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  overflow: auto;
  margin: 0;
}
#root {
  min-height: 100%;
}

.uclusion-app {
  min-height: 100%;
  width: 100%;
}
.calculator-card {
  min-width: 80rem;
  padding: 3rem 1rem 1rem;
}
.app-header {
  top: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
  z-index: 1201;
}
.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}
.videoWrapper2 {
  width: 90% !important;
  height: 90% !important;
  max-width: 1000px;
  margin-right: auto;
  display: block;
  margin-left: auto;
  padding-bottom: 2rem;
}
.videoWrapper {
  width: 53% !important;
  height: 53% !important;
  max-width: 1000px;
  margin-right: auto;
  display: block;
  margin-left: auto;
  padding-bottom: 2rem;
}
.logo {
  width: 150px;
}
.logo2 {
  height: 50px;
}
.logo-container {
  margin-left: 1%;
  padding: 1rem;
}
.logoMobile {
  display: none;
}
.calcDetailsButton.MuiButton-root {
  background-color: white;
  color: black;
  text-transform: none;
  font-size: small
}
.primary-color {
  color: #3f6b72;
}
.my-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
.primary-background-color {
  background-color: #3f6b72;
}
.secondary-color {
  color: #9f5000;
  padding: 1rem 0;
}
.cta.MuiTypography-h2 {
  font-weight: 700;
}
.homer.MuiTypography-h4 {
  font-weight: 600;
}
.cta.MuiTypography-h4 {
  width: 700px;
  font-weight: 600;
}
.desktop-dialog {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 900px;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.desktop-swimlanes {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1100px;
}

.desktop-initiative {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 1100px;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.mobile-dialog,
.mobile-swimlanes,
.mobile-initiative {
  display: none;
}
.image-blurb {
  flex: 0 0 700px;
}
.image-blurb-dialog {
  flex: 0 0 700px;
}
.screen-home-first {
  display: flex;
  margin-right: 20%;
}
.screen-home-third {
  display: flex;
  margin-right: 6%;
  padding-bottom: 1rem;
}
.screen-home-second {
  display: flex;
  margin-right: 12%;
  padding-bottom: 1rem;
}
.screen-about-first {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.screen {
  display: flex;
  background: linear-gradient(180deg, rgba(63, 107, 114, 0) 0%, rgba(63, 107, 114, 0.08) 100%);
  padding-bottom: 2rem;
}
.home-screen {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
.btn.MuiButton-root {
  text-transform: capitalize;
  box-shadow: none;
  background-color: #fff;
}
.btn.MuiButton-root:hover {
  box-shadow: none;
}
.walkthrough-btn.MuiButton-root {
  border-radius: 32px;
}
.submit-btn.MuiButton-root {
  border-radius: 32px;
  background-color:#316BBE ;
  color: #fff;
  padding: 10px;
  font-weight: bold;
}
.submit-btn.btn.MuiButton-root:hover {
  background-color:#316BBE;
  opacity: .7;
}
.btn-container {
  width: 100%;
}
.btn-container-two {
  width: 100%;
  padding-top: 2rem;
}
h2.mainStatement {
  color: #3f6b72;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.home-container-team {
  margin-left: auto;
  margin-right: auto;
}
.home-container-team-autonomous {
  min-width: 25rem;
  margin-left: auto;
  margin-right: 3rem;
  margin-top: 2rem;
}
.home-container-image,
.home-container {
  min-width: 25rem;
  margin-left: auto;
  margin-top: 8rem;
}
.home-container-second {
  min-width: 25rem;
  margin: 5px;
  border-radius: 6px;
  padding: 15px;
  border: 2px solid black;
}
.home-container-home {
  min-width: 25rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.move-right {
  margin-left: 0;
}

.container-home {
  width: 1050px;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}

.container-home-collab {
  width: 1200px;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}
.container-pricing {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  padding: 1rem;
}
.container {
  width: 900px;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}
.sub-text-home-pad-top {
  padding-bottom: 2rem;
  padding-left: 1rem;
}
.sub-text-home-above {
  padding-top: 2rem;
}
.sub-text-home {
  padding-bottom: 4rem;
}
.sub-text {
  padding-top: 2rem;
}
.sign-up,
.sign-up-page {
  padding: 2rem;
  width: 20rem;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid rgba(208, 208, 208, 0.5);
}
.sign-up-page {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
.header-text {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.sign-up-form > div {
  margin-bottom: 1rem;
}
.sign-up-page-form > div {
  margin-bottom: 1rem;
}
.privacy-text.MuiTypography-root {
  color: rgba(34, 47, 46, 0.7);
  font-size: 12px;
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.sign-in-text.MuiTypography-root {
  color: #222222;
  font-size: 15px;
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  font-weight: 300;
}
.status-text.MuiTypography-h2 {
  font-weight: 700;
  min-width: 20rem;
  margin-right: 5rem;
  margin-top: 3rem;
  font-size: 56px;
}
.spacer-text {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 26px;
}
.inline-hr {
  width: 100%;
  margin-top: .6rem;
  border: none;
  height: 1px;
  color: #aaa;
  background-color: #aaa;
}
.hr {
  flex: 5
}
.or-text {
  color: #aaa;
  margin-right: 5px;
  margin-left: 5px;
}
.status-container {
  display: flex;
}
.speech-container {
  min-width: 600px;
}
.z-one {
  z-index: 1;
  margin-right: 1rem;
}
.z-two {
  z-index: 2;
  margin-left: -2rem;
}
.z-three {
  z-index: 3;
}
.z-four {
  z-index: 4;
}
.speech-bubble {
  position: relative;
}
.no-margin {
  margin-top: 0
}
.margin-75 {
  margin-top: -75px;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.bubble-text {
  position: absolute;
  text-align: center;
  max-width: 16rem;
  color: #fff;
  top: 56px;
}
.indent-left {
  left: 114px;
}
.indent-right {
  right: 132px;
}
.status {
  flex-direction: column;
  width: 1024px;
}
.swim-one {
  z-index: 1;
}
.swim-two {
  z-index: 2;
  position: relative;
  top: -100px;
  right: -34px;
}
.status-logo {
  width: 10rem;
}
.arrow-container {
  display: flex;
  justify-content: space-around;
  margin-top: -9rem;
  z-index: 4;
  position: relative;
  margin-left: -7rem;
}
.arrow-one {
  display: flex;
  flex-direction: column;
}
.arrow-two {
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  margin-left: -10rem;
  margin-top: .5rem;
}
.arrow-three {
  display: flex;
  flex-direction: column;
  left: -140px;
  position: relative;
}
.arrow-image.right {
  text-align: right;
  margin-right: 2rem;
}
.arrow-image.center {
  text-align: center;
}
.update-text {
  font-weight: 600;
}
.requirements-text.MuiTypography-h2 {
  font-weight: 700;
  min-width: 20rem;
  margin-top: 5rem;
  margin-right: 5rem;
  font-size: 56px;
}
.requirements-container {
  display: flex;
  justify-content: space-between;
}
.requirements {
  flex-direction: column;
}
.req-logo {
  width: 10rem;
}
.calendar-image {
  margin-left: 5rem;
  position: relative;
  top: -40px;
  z-index: 6;
}
.slack-image {
  margin-right: 5rem;
  position: relative;
  top: -80px;
  z-index: 7;
}
.decisions-text.MuiTypography-h2 {
  font-weight: 700;
  min-width: 20rem;
  margin-top: 1rem;
  margin-right: 5rem;
  font-size: 56px;
}
.setup-image {
  position: relative;
}
.setup {
  margin-top: -5rem;
}
.footer-container {
  width: 100%;
  background-color: #3F6B72;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.new {
  display: block;
  width: 100%;
}
.pricing-page .new-screen {
  bottom: 0;
  position: fixed;
  width: 100%;
}
.sign-up-text.MuiTypography-h2 {
  color: #fff;
  font-weight: 800;
  width: 30rem;
}
.sign-up-container {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  padding: 5rem;
  padding-bottom: 0;
}
.sign-up-btn.MuiButton-root,
.sign-up-top-btn.MuiButton-root,
.whoAmiBtn.MuiButton-root {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  line-height: 2;
  font-weight: 500;
  font-size: 14px;
  background: #3F6B72;
  color: #FFFFFF;
  border-radius: 8px;
  width: auto;
  text-transform: none;
}
.whoAmiBtn.MuiButton-root {
  text-transform: none;
  box-shadow: none;
  padding-left: 15px;
  padding-right: 15px;
}
.sign-up-btn.MuiButton-root {
  background: #FFFFFF;
  color: #3F6B72;
}
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 3%;
}
.login-container-header {
  margin-top: auto;
  margin-bottom: auto;
}
.btn-container-header {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0;
  padding-left: 0;
}
.sign-up-btn.btn.MuiButton-root:hover {
  opacity: .7;
  background: #FFFFFF;
}
.sign-up-top-btn.MuiButton-root:hover,
.sign-up-inline-btn.MuiButton-root:hover {
  opacity: .7;
  background: #3F6B72;
}
.sign-up-inline-btn.MuiButton-root {
  border: 2px solid #3F6B72;
  color: black;
  border-radius: 20px;
  font-size: 1.5rem;
  padding: 6px 16px;
  font-weight: bold;
  text-transform: none;
}
.walkthrough.MuiButton-root:hover {
  opacity: .7;
  background: #3F6B72;
}
.sign-up-button-container {
  padding: 3rem;
  text-align: center;
}
.sign-up-link {
  cursor: pointer;
  text-decoration: underline;
  color: #0900ed;
}
.google-button {
  width: 100%;
  height: 46px;
  background-color: #4285f4;
  border: none;
  color: #fff;
  line-height: 46px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 1rem;
}
.google-text {
  line-height: 46px;
  display: inline-block;
  width: auto;
  font-size: 1rem;
}
.google-img {
  transform: scale(1.15);
}
.google-text-container {
  margin-left: auto;
  margin-right: auto;
}
.swim-mobile,
.email-mobile,
.onboarding-mobile,
.options-mobile {
  display: none;
}
.workspace-mobile {
  display: none;
}
.red {
  color: #F44335;
}
.password-toggle {
  cursor: pointer;
}
.MuiButton-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.26);
}
.email {
  width: 75%;
}
.blog-home-video {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
}
.blog-home-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
}
.blog-home-nobottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.blog-home {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14rem;
}
.blog-autonomous {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 343px);
  padding-top: 1rem;
}
.blog {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 343px);
  padding-top: 8rem;
  padding-bottom: 3rem;
}
.guide {
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
}
.blog img {
  width: 100%;
}

.aboutus-screen {
  display: flex;
  padding-top: 10rem;
  padding-bottom: 6rem;
}
.aboutus {
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: block;
}
.workspace-text {
  padding-bottom: 0;
  min-width: 900px;
  display: block;
}
.aboutus-section-centered{
  display: block;
  width: 85%;
  padding-left: 4rem;
  padding-bottom: 2rem;
}
.screen-about-autonomous {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.aboutus-section-home{
  display: block;
}
.aboutus-section{
  display: block;
  padding-bottom: 2rem;
}
.aboutus-section-second{
  display: block;
  padding-bottom: 4rem;
}
.aboutus-section-top-home-pad {
  display: block;
  padding-top: 0;
  padding-bottom: 3rem;
}
.aboutus-section-top{
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.guide-section-title{
  display: block;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.green-background {
  background: linear-gradient(180deg, rgba(63, 107, 114, 0.08) 0%, rgba(63, 107, 114, 0) 100%);
  padding-top: 3rem;
}
.pricing-screen {
  display: flex;
  padding-bottom: 2rem;
}
.home-top-below-header {
  padding-top: 8rem;
  display: block;
}
.how-about {
  display: block;
}
.team-free {
  padding-top: 2rem;
  display: block;
}
.how {
  margin-top: 1rem;
  display: block;
}
.pricing-link {
  text-decoration: none;
  color: black;
  font-size: 1.25rem;
  margin-right: 3rem;
  white-space: nowrap;
}
.plain-link {
  text-decoration: none;
}
.aboutus-link {
  text-decoration: none;
  color: black;
  font-size: .875rem;
  margin-right: 3rem;
}
.footer-link-tube {
  text-decoration: none;
  color: white;
  font-size: .875rem;
  margin-right: 1rem;
}
.footer-link {
  text-decoration: none;
  color: white;
  font-size: .875rem;
  margin-right: 1rem;
  padding-top: 1rem;
}
.competition-link {
  text-decoration: none;
  color: black;
  font-size: .875rem;
  margin-right: 3rem;
}
.sign-up-btn-container {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.header-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.button-box{
  margin-right: 3rem;
  display: inline-block;
}
.login-btn.btn.MuiButton-root:hover {
  background: #FFFFFF;
}
.links {
  line-height: 36px;
}
.menu-btn.MuiIconButton-root {
  display: none;
}
.copyright-container {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}
.copyright {
  color: white;
  bottom: 10px;
  right: 40px;
}
.social-logo {
  width: 30px;
  margin-left: 20px;
}
.social-logo.MuiSvgIcon-root {
  width: 30px;
  height: auto;
}
.whoami-text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.whoami-logo {
  width: 200px;
}
.footer-logos {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: .5rem;
}
.requirements-image-container > .setup-image {
  max-width: 20rem;
}

.notifications-image-container > .setup-image {
  max-width: 85%;
  max-height: 85%;
  display: block;
}

.nobacklog-image-container > .setup-image {
  max-width: 45%;
  max-height: 45%;
  display: block;
}
.pricing-personal {}
.requirements-image-container > .notifications-image {
  max-width: 25rem;
  max-height: unset;
}
.workspace-container .workspace-img {
  width: 80%;
}
.modal-logo {
  width: 100px;
}
.modal-body {
  text-align: center;
  position: absolute;
  max-width: 350px;
  background: #efefef;
  left: 40%;
  top: 30%;
  padding: 2rem 1rem;
  border-radius: 4px;
}
.modal-body .helper-text {
  padding: 1rem;
}
.form-control.MuiFormControl-root {
  min-width: 300px;
}
.how-grid {
  max-width: 1024px;
  padding: 1rem;
}
.desktop-drawer {
  width: 8rem;
  flex-shrink: 0;
}
.mobile-drawer {
  display: none;
}
.drawer-paper {
  width: 8rem;
}
.list-selected {
  color: blue;
}
.how-item.MuiGrid-item {
  margin-top: 2rem;
}
.how-icon-container {
  text-align: left;
}
.how-section-link {
  padding-top: 10px;
}
.how-section-intro.MuiTypography-root {
  margin-right: 5px;
  font-weight: bold;
}
.zoom-icon {
  opacity: 0.4;
}
.at-section-body {
  padding-top: 0.5rem;
}
.home-container-team-autonomous {
  margin-top: 0;
}
.home-workspace-space {
  padding-right: 2rem;
  color: #3f6b72;
}
.container-home-autonomous {}
.card {
  max-width: 34rem;
  text-align: center;
  margin-top: 2rem;
}
.documentation-pictures {
  max-width: 50%;
}
.drawer-list.MuiList-padding {
  padding-top: 9rem;
}

/*mobile*/
@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .at-section-body,
  .how-section-body,
  .how-section-link {
    padding-right: 2rem;
  }
  .videoWrapper {
    width: 100% !important;
    height: 100% !important;
    margin-left: unset;
  }
  .home-container-home {
    margin-top: 5rem;
  }
  .container-home {
    width: unset;
  }
  .calculator-card {
    min-width: 20rem;
    max-width: fit-content;
    padding: 2rem 0 1rem;
  }
  .workspace-text {
    padding-right: 1rem;
    padding-bottom: 0;
    min-width: unset;
    display: block;
  }
  .blog-home-video {
    margin-left: 1rem;
  }
  .home-workspace-space {
    padding-right: unset;
  }
  .home-workspace-space.MuiTypography-h2 {
    font-size: xx-large;
  }
  .aboutus-section-second {
    padding-right: 2rem;
  }
  .home-container-image {
    margin-top: 0;
  }
  .mobile-dialog {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .mobile-initiative {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 500px;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .mobile-swimlanes {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
  }
  .desktop-dialog,
  .desktop-swimlanes,
  .desktop-initiative {
    display: none;
  }
  .image-blurb-dialog,
  .image-blurb {
    flex: unset;
    padding-top: 0;
  }
  .image-blurb-dialog.MuiTypography-h3,
  .image-blurb.MuiTypography-h3 {
    font-size: xx-large;
    padding-left: 1rem;
  }
  .aboutus-section-home{
    padding-left: 0;
    padding-right: 3rem;
  }
  .homer.MuiTypography-h2 {
    padding-top: 6rem;
  }
  .aboutus-section-top-home-pad {
    padding-top: 6rem;
    padding-left: 1rem;
    padding-right: 2rem;
  }
  .aboutus-section-top-home-pad.MuiTypography-h4 {
    font-size: x-large;
  }
  .pricing-personal {
    padding-top: 3rem;
  }
  .menu-btn.MuiIconButton-root {
    display: block;
    margin-right: 8px;
  }
  .screen-about-autonomous {
    margin-right: 2rem;
  }
  .container-home-collab {
    display: inline-block;
    width: 90%;
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .home-container-team-autonomous {
    margin-right: 1.5rem;
    min-width: auto;
  }
  .screen-home-first {
    display: flex;
    padding-top: 0;
  }
  .screen-home-second {
    display: flex;
  }
  .team-free {
    display: block;
  }
  .app-header {
    flex-direction: row;
  }
  .logo2,
  .logo {
    display: none;
  }
  .logoMobile {
    display: unset;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }
  .logo-container {
    margin-left: 0;
    padding: 1.125rem .5rem 1rem 1rem;
  }
  .header-flex-container {
    height: 100%;
  }
  .mobile-flex {
    display: flex;
    flex-direction: row;
  }
  .pricing-link {
    line-height: 36px;
    white-space: nowrap;
  }
  .login-container-header,
  .btn-container-header {
    display: none;
  }
  .header-flex-container {
    display: none;
  }
  .video {
    display: block;
  }
  .btn-container-two {
    display: none;
  }
  .aboutus-section-centered{
    display: block;
    width: 90%;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
  .container-home-autonomous,
  .container-home,
  .container {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .MuiTypography-root.sub-text-home-above.buttons {
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .MuiTypography-root.sub-text-home.buttons {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .MuiTypography-root.sub-text.buttons {
    text-align: center;
  }
  .sign-up-inline-btn.MuiButton-root {
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-transform: none;
  }
  .status-container,
  .requirements-container,
  .notifications-container,
  .sign-up-container {
    min-width: auto;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  .cta.MuiTypography-h4 {
    width: auto;
    font-size: 1.5rem;
  }
  .sign-up,
  .sign-up-page {
    width: auto;
    margin-right: 0;
  }
  .github {
    font-size: .8rem !important;
    padding-right: 0 !important;
  }
  .google-text {
    font-size: .8rem;
    margin-left: 0;
  }
  .home-screen > .container {
    padding: 0;
  }
  .status-text,
  .requirements-text,
  .decisions-text {
    min-width: auto;
    margin-right: 0;
  }
  .lesser-text {
    padding-bottom: 3rem;
  }
  .speech-container {
    transform: scale(.45);
    position: relative;
    left: -50%;
    top: -50px;
  }
  .swim-one,
  .swim-two,
  .arrow-container,
  .workspace-img,
  .email,
  .setup-browser,
  .options {
    display: none;
  }
  .swim-mobile {
    display: block;
  }
  .swim-mobile > img {
    width: 100%;
  }
  .status-text.MuiTypography-h2 {
    margin-right: 0;
    font-size: 36px;
    min-width: auto;
    margin-top: 2rem;
  }
  .decisions-text.MuiTypography-h2 {
    margin-right: 0;
    font-size: 36px;
    min-width: auto;
  }
  .swim-lane-container {
    margin-top: -50px;
  }
  .requirements-text.MuiTypography-h2,
  .notifications-text.MuiTypography-h2,
  .sign-up-text.MuiTypography-h2 {
    font-size: 36px;
    min-width: auto;
    margin-right: 0;
    margin-top: 2rem;
  }
  .requirements-image-container {
    padding: 1rem;
  }
  .requirements-image-container > img {
    width: 100%;
  }
  .requirements-image-container > .setup-image {
    width: calc(100% - 2rem);
  }
  .workspace-mobile {
    display: block;
    width: 100%;
  }
  .email-container {
    padding: 1rem;
  }
  .container .email-mobile,
  .container .options-mobile {
    display: block;
    width: 100%;
  }
  .decision-speech {
    left: -170px;
    margin-top: -50px;
    margin-bottom: -50px;
  }
  .setup {
    margin-top: 0;
  }
  .onboarding-mobile {
    display: block;
    width: 100%;
  }
  .sign-up-text.MuiTypography-h2 {
    width: auto;
    padding: 1rem;
    text-align: center;
  }
  .sign-up-button-container {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  .decisions {
    margin-top: -100px;
  }
  .sign-up-container {
    width: auto;
    padding: 0;
  }
  .new-screen {
    margin-top: -1rem;
  }
  .inform-text {
    padding-left: 20px;
    margin-top: -100px;
  }
  .blog-autonomous,
  .blog-home {
    width: auto;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .blog {
    width: auto;
    margin: 0;
    padding: 1rem;
    padding-top: 5rem;
  }
  .blog img {
    width: 100%;
  }
  .footer-logos,
  .copyright-container {
    width: auto;
  }
  .copyright-container {
    margin-right: 1rem;
  }
  .footer-logos {
    padding-right: 1rem;
    flex-direction: column;
  }
  .footer-link {
    margin-left: 1rem;
  }
  .footer-link-tube {
    margin-left: .5rem;
    line-height: 1.5rem;
  }
  .youtube {
    display: flex;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .footer-logo-container {
    margin-left: 1rem;
  }
  .footer-logo {
    width: 80px;
  }
  .pricing-free.MuiTypography-h4 {
    font-size: 1.125rem;
  }
  .pricing-page .new-screen {
    position: relative;
  }
  .aboutus {
    padding: 2rem;
    margin-top: 0;
  }
  .aboutus-screen {
    padding-top: 5rem;
    padding-bottom: 0;
  }
  .desktop-drawer {
    display: none;
  }
  .mobile-drawer {
    display: unset;
  }
  .drawer-list.MuiList-padding {
    padding-top: 5rem;
  }
  .documentation-pictures {
    max-width: 95%;
  }
}
/* ipad */
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .speech-container {
    transform: scale(.75);
    left: 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .requirements-text.MuiTypography-h2,
  .notifications-text.MuiTypography-h2,
  .sign-up-text.MuiTypography-h2,
  .status-text.MuiTypography-h2,
  .decisions-text.MuiTypography-h2 {
    font-size: 56px;
    text-align: center;
  }
  .header-width {
    display: none;
  }
  .container-header {
    margin-right: unset;
  }
  .desktop-drawer {
    display: none;
  }
  .mobile-drawer {
    display: unset;
  }
  .drawer-list.MuiList-padding {
    padding-top: 5rem;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1367px) {
  .logo-container {
    margin-left: 3%;
  }
  .container-header {
    margin-right: unset;
  }
}
.react-reveal{
  animation-fill-mode: backwards !important;
}